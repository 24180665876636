import React, {useEffect} from "react";
import AppContainer from "../../common/app_container";
import {useUserAPI} from "@src/api/user.api";
import {User} from "@src/model/user";
import {useNavigate, useParams} from "react-router-dom";
import {useForm, SubmitHandler} from "react-hook-form";
import Alert, {AlertProps} from "@src/common/alert";

interface UserEditProps {

}
const UserEdit: React.FC<UserEditProps> = (props) => {
    const {id} = useParams();
    const userAPI = useUserAPI();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [status, setStatus] = React.useState<AlertProps|null>(null);
    const [isSaving, setIsSaving] = React.useState(false);
    const [roles, setRoles] = React.useState<string[]>([]);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors },
    } = useForm<User>()
    const onSubmit: SubmitHandler<User> = (data) => {
        setIsSaving(true);
        userAPI.saveUser(data).then(() => {
            setIsSaving(false);
            setStatus({type: "success", message:"User saved successfully"});
            setTimeout(() => {
                navigate("/users");

            }, 3000)
        })
            .catch((err) => {
                setIsSaving(false);
                setStatus({type: "error", message:"Error saving user: " + err.message});
            });
    }

    useEffect(() => {
        userAPI.getRoles().then((response) => {
            setRoles(response);
        });
        if(id) {
            userAPI.getUser(id).then((response) => {
                reset(response);
            });
        }
    }, [id]);

    return (
        <AppContainer>
            <div className="container">
                <div className="flex justify-between">
                    <h1 className="text-3xl">{id?"Edit User":"Add User"}</h1>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit" form="user_form" disabled={isSaving}>
                        Save
                    </button>
                </div>
                {status && <Alert {...status}/>}
                <form onSubmit={handleSubmit(onSubmit)} id="user_form">
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-900">
                                First Name
                            </label>
                            <div className="mt-2">
                                <div
                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        {...register("first_name")}
                                        type="text"
                                        placeholder="First Name"
                                        autoComplete="first_name"
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                                First Name
                            </label>
                            <div className="mt-2">
                                <div
                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        {...register("last_name")}
                                        type="text"
                                        placeholder="Last Name"
                                        autoComplete="last_name"
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-900">
                                Full Name
                            </label>
                            <div className="mt-2">
                                <div
                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        {...register("full_name")}
                                        type="text"
                                        placeholder="Full Name"
                                        autoComplete="full_name"
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="is_active" className="block text-sm font-medium leading-6 text-gray-900">
                                Is Active
                            </label>
                            <div className="mt-2">
                                <div
                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <select
                                        {...register("is_active")}
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    >
                                        <option value="">-- Select Active --</option>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="user_type" className="block text-sm font-medium leading-6 text-gray-900">
                                User Type
                            </label>
                            <div className="mt-2">
                                <div
                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <select
                                        {...register("user_type")}
                                        autoComplete="user_type"
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    >
                                        <option value="">-- Select User Type --</option>
                                        <option value="creator">Creator</option>
                                        <option value="rights_holder">Rights Holder</option>
                                        <option value="admin">Admin</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="roles" className="block text-sm font-medium leading-6 text-gray-900">
                                Roles
                            </label>
                            <div className="mt-2">
                                <div
                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <select
                                        {...register("roles")}
                                        multiple
                                        autoComplete="roles"
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    >
                                        {roles.map((role) => (
                                            <option key={role} value={role}>{role}</option>
                                        ))}
                                    </select>
                                </div>

                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                Email
                            </label>
                            <div className="mt-2">
                                <div
                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        {...register("email")}
                                        type="email"
                                        placeholder="Email"
                                        autoComplete="email"
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                Password (leave blank if you don't want to change)
                            </label>
                            <div className="mt-2">
                                <div
                                    className="relative flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input {...register("password")}
                                           type={showPassword ? "text" : "password"}
                                           id="hs-toggle-password"
                                           className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                           placeholder="Enter password"/>
                                    <button type="button" onClick={() => setShowPassword(cur => !cur)}
                                            data-hs-toggle-password='{
            "target": "#hs-toggle-password"
          }'
                                            className="absolute inset-y-0 end-0 flex items-center z-20 px-3 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus:text-blue-600">
                                        <svg className="shrink-0 size-3.5" width="24" height="24" viewBox="0 0 24 24"
                                             fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                             stroke-linejoin="round">
                                            <path className="hs-password-active:hidden"
                                                  d="M9.88 9.88a3 3 0 1 0 4.24 4.24"></path>
                                            <path className="hs-password-active:hidden"
                                                  d="M10.73 5.08A10.43 10.43 0 0 1 12 5c7 0 10 7 10 7a13.16 13.16 0 0 1-1.67 2.68"></path>
                                            <path className="hs-password-active:hidden"
                                                  d="M6.61 6.61A13.526 13.526 0 0 0 2 12s3 7 10 7a9.74 9.74 0 0 0 5.39-1.61"></path>
                                            <line className="hs-password-active:hidden" x1="2" x2="22" y1="2"
                                                  y2="22"></line>
                                            <path className="hidden hs-password-active:block"
                                                  d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z"></path>
                                            <circle className="hidden hs-password-active:block" cx="12" cy="12"
                                                    r="3"></circle>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </AppContainer>
    )
};

export default UserEdit;