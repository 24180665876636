import { useAuth0 } from "@auth0/auth0-react";
import { LicensableProperty, Price } from "../model/licensable_property";
import { UploadedFile } from "../api/file_api";
import {User} from "@src/model/user";

export interface BlogPost {
  id?: string;
  slug?: string;
  title?: string;
    content?: string;
    status?: "DRAFT"|"PUBLISHED"|"ARCHIVED"|undefined;
    thumbnail?: string;
    published_at?: Date;
}

export function useBlogAPI() {
  const { getAccessTokenSilently } = useAuth0();

  const getBlogPost = async ( id: string ): Promise<BlogPost> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
      },
    });

    return new Promise<BlogPost>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/blog/post/${id}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: BlogPost) => {
            if (data.published_at) {
              data.published_at = new Date(data.published_at);
            }
            resolve(data);
          });
        } else {
          reject(new Error("There was an issue retrieving the post` " + response.statusText));
        }
      });
    });
  };


  const fetchBlogPosts = async (query: string, limit: number=100, offset: number=0): Promise<BlogPost[]> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
        scope: "read:current_user", // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
      },
    });

    return new Promise<BlogPost[]>((resolve, reject) => {
      const params = new URLSearchParams();
      params.append("q", query);
      params.append("limit", limit.toString());
      params.append("offset", offset.toString());
      fetch(`${process.env.REACT_APP_API_URL}/blog/posts/list?${params.toString()}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: BlogPost[]) => {
            resolve(data);
          });
        } else {
          reject(new Error("There was an issue retrieving the posts " + response.statusText));
        }
      });
    });
  };

  const saveBlogPost = async (saveReq: BlogPost): Promise<BlogPost> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
        scope: "read:current_user", // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
      },
    });

    return new Promise<BlogPost>((resolve, reject) => {
      const url = `${process.env.REACT_APP_API_URL}/blog/post${saveReq.id ? `/${saveReq.id}` : ""}`;
      fetch(url, {
        method: saveReq.id ? "PUT" : "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(saveReq),
      }).then((response) => {
        if (response.status >= 200 && response.status < 300) {
          response.json().then((data: BlogPost) => {
            if (data.published_at) {
              data.published_at = new Date(data.published_at);
            }
            resolve(data);
          });
        } else {
          if (response.status === 401) {
            reject(new Error("User is not authenticated"));
          } else {
            reject(new Error("Failed to save: " + response.statusText));
          }
        }
      });
    });
  };
  return { getBlogPost, fetchBlogPosts, saveBlogPost };
}