import React, {useEffect} from "react";
import AppContainer from "../../common/app_container";
import DataTable from "react-data-table-component";
import {useUserAPI} from "@src/api/user.api";
import {User} from "@src/model/user";
import {useNavigate} from "react-router-dom";
interface UserIndexProps {

}
const UserIndex: React.FC<UserIndexProps> = (props) => {
    const [data, setData] = React.useState<User[]>([]);
    const [search, setSearch] = React.useState("");
    const userAPI = useUserAPI();
    const navigate = useNavigate();
    const columns = [
        {
            name: "Name",
            selector: (row: any) => row.full_name,
            sortable: true
        },
        {
            name: "Email",
            selector: (row: any) => row.email,
            sortable: true
        },
        {
            name: "User Type",
            selector: (row: any) => row.user_type,
            sortable: true
        },
        {
            name: "Actions",
            cell: (row: any) => <div>
                <a href={`/users/edit/${row.id}`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Edit
                </a>

            </div>
        }
    ];

    useEffect(() => {
        userAPI.getUsers(search, 100, 0).then((response) => {
            setData(response);
        });
    }, []);

    return (
        <AppContainer>
            <div className="container">
                <div className="flex justify-between">
                    <h1 className="text-3xl">Users</h1>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={()=>navigate("/users/add")}>
                        Add User
                    </button>
                </div>
                <DataTable columns={columns}
                           data={data}/>

    </div>
    </AppContainer>
)};

export default UserIndex;