import React, {useEffect} from "react";
import AppContainer from "../../common/app_container";
import DataTable from "react-data-table-component";
import {useBlogAPI, BlogPost} from "@src/api/blog_api";
import {useNavigate} from "react-router-dom";
interface BlogIndexProps {

}
const BlogIndex: React.FC<BlogIndexProps> = (props) => {
    const [data, setData] = React.useState<BlogPost[]>([]);
    const [search, setSearch] = React.useState("");
    const blogAPI = useBlogAPI();
    const navigate = useNavigate();
    const columns = [
        {
            name: "Title",
            selector: (row: any) => row.title,
            sortable: true
        },
        {
            name: "Status",
            selector: (row: any) => row.status,
            sortable: true
        },
        {
            name: "Published Date",
            selector: (row: any) => row.published_at,
            sortable: true
        },
        {
            name: "Actions",
            cell: (row: any) => <div>
                <a href={`/blog/edit/${row.id}`}
                   className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Edit
                </a>

            </div>
        }
    ];

    useEffect(() => {
        blogAPI.fetchBlogPosts(search, 100, 0).then((response) => {
            setData(response);
        });
    }, []);

    return (
        <AppContainer>
            <div className="container">
                <div className="flex justify-between">
                    <h1 className="text-3xl">Posts</h1>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={()=>navigate("/blog/add")}>
                        Add Post
                    </button>
                </div>
                <DataTable columns={columns}
                           data={data}/>

    </div>
    </AppContainer>
)};

export default BlogIndex;