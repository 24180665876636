import React, {useEffect} from "react";
import AppContainer from "../../common/app_container";
import DataTable from "react-data-table-component";
import {useUserAPI} from "@src/api/user.api";
import {User} from "@src/model/user";
import {useNavigate} from "react-router-dom";
import {Project, useProjectAPI} from "@src/api/project_api";
interface UserIndexProps {

}
const ProjectIndex: React.FC<UserIndexProps> = (props) => {
    const [data, setData] = React.useState<Project[]>([]);
    const [search, setSearch] = React.useState("");
    const projectApi = useProjectAPI();
    const navigate = useNavigate();
    const columns = [
        {
            name: "Name",
            selector: (row: any) => row.name,
            sortable: true
        },
        {
            name: "Status",
            selector: (row: any) => row.status,
            sortable: true
        },
        {
            name: "License",
            selector: (row: any) => row.license_type,
            sortable: true
        },
        {
            name: "Created By",
            selector: (row: any) => row.created_by_user,
            sortable: true
        },
        {
            name: "Created At",
            selector: (row: any) => row.created_at,
            sortable: true
        },
        {
            name: "Talent",
            selector: (row: any) => row.licensable_properties.map((lp: any) => lp.name).join(", "),
            sortable: true
        },
    ];

    useEffect(() => {
        projectApi.adminGetProjects(search, 100, 0).then((response) => {
            setData(response);
        });
    }, []);

    return (
        <AppContainer>
            <div className="container">
                <div className="flex justify-between">
                    <h1 className="text-3xl">Projects</h1>

                </div>
                <DataTable columns={columns}
                           data={data}/>

    </div>
    </AppContainer>
)};

export default ProjectIndex;