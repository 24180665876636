import { User } from "../model/user";
import { useAuth0 } from "@auth0/auth0-react";

export function useUserAPI() {
  const { getAccessTokenSilently } = useAuth0();

  const fetchUserProfile = async (): Promise<User> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
        scope: "read:current_user", // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
      },
    });

    return new Promise<User>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/user/me`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: User) => {
            resolve(data);
          });
        } else {
          reject(new Error("User is not authenticated"));
        }
      });
    });
  };

  const registerUser = async (profile: User): Promise<User> => {
    return new Promise<User>((resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/user/register`;
        fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(profile),
        }).then((response) => {
          if (response.status === 200) {
            response.json().then((data: User) => {
              resolve(data);
            });
          } else {
            if(response.status === 401) {
                reject(new Error("User is not authenticated"));
            }else {
              response.json().then((err: any) => {
                reject(new Error(err.message || JSON.stringify(err)));
              }).catch(() => {
                reject(new Error(response.statusText));
              });
            }

          }
        });
      } catch (error) {
        console.log("WTF", error);
      }
    });
  };

  const saveUserProfile = async (profile: User): Promise<User> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD,
        scope: "update:current_user_metadata",
      },
    });
    return new Promise<User>((resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/user/${profile.id}`;
        fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(profile),
        }).then((response) => {
          if (response.status === 200) {
            response.json().then((data: User) => {
              resolve(data);
            });
          } else {
            reject(new Error("User is not authenticated"));
          }
        });
      } catch (error) {
        console.log("WTF", error);
      }
    });
  };
  const getUsers = async (query: string, limit: number=100, offset: number=0): Promise<User[]> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
        scope: "read:current_user", // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
      },
    });

    return new Promise<User[]>((resolve, reject) => {
      const params = new URLSearchParams();
      params.append("q", query);
      params.append("limit", limit.toString());
      params.append("offset", offset.toString());
      fetch(`${process.env.REACT_APP_API_URL}/user/admin/list?${params.toString()}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: User[]) => {
            resolve(data);
          });
        } else {
          reject(new Error("User is not authenticated"));
        }
      });
    });
  };
  const getRoles = async (): Promise<string[]> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
        scope: "read:current_user", // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
      },
    });

    return new Promise<string[]>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/user/admin/roles`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: string[]) => {
            resolve(data);
          });
        } else {
          reject(new Error("User is not authenticated"));
        }
      });
    });
  }
  const getUser = async (id: string|null): Promise<User> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
        scope: "read:current_user", // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
      },
    });

    return new Promise<User>((resolve, reject) => {
      fetch(`${process.env.REACT_APP_API_URL}/user/admin/${id}`, {
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((data: User) => {
            resolve(data);
          });
        } else {
          reject(new Error("User is not authenticated"));
        }
      });
    });
  };
  const saveUser = async (profile: User): Promise<User> => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
        scope: "read:current_user", // Scope that exists for the API being called. You can create these through the Auth0 Management API or through the Auth0 Dashboard in the Permissions view of your API.
      },
    });
    return new Promise<User>((resolve, reject) => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/user/admin${profile.id?('/'+profile.id):''}`;
        fetch(url, {
          method: profile.id? "PUT":"POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${accessToken}`
          },
          body: JSON.stringify(profile),
        }).then((response) => {
          if (response.status === 200) {
            response.json().then((data: User) => {
              resolve(data);
            });
          } else {
            if(response.status === 401) {
              reject(new Error("User is not authenticated"));
            }else {
              response.json().then((err: any) => {
                reject(new Error(err.message || JSON.stringify(err)));
              }).catch(() => {
                reject(new Error(response.statusText));
              });
            }

          }
        });
      } catch (error) {
        console.log("WTF", error);
      }
    });
  };


  return { fetchUserProfile, saveUserProfile, registerUser, getRoles, getUsers, getUser, saveUser  };
}
