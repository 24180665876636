import {useAuth0} from "@auth0/auth0-react";
import axios, {AxiosProgressEvent} from "axios";
import {FileUploadRequest, UploadedFile} from "@src/api/file_api";
import {LicensableProperty} from "@src/model/licensable_property";

export interface EventCount {
    date: Date;
    count: number;
}

export function useAdminDashboardAPI() {
    const {getAccessTokenSilently} = useAuth0();

    const getUserSignups = async (): Promise<EventCount[]> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
            },
        });

        return new Promise<EventCount[]>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/admin/dashboard/user-signups`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: EventCount[]) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    };
    const getProjects = async (): Promise<EventCount[]> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
            },
        });

        return new Promise<EventCount[]>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/admin/dashboard/projects`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: EventCount[]) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    };
    const getLicensableProperties = async (): Promise<EventCount[]> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
            },
        });

        return new Promise<EventCount[]>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/admin/dashboard/licensable-properties`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: EventCount[]) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    };
    const getCounts = async () : Promise<Map<string,number>> => {
        const accessToken = await getAccessTokenSilently({
            authorizationParams: {
                audience: process.env.REACT_APP_AUTH0_AUD, // Value in Identifier field for the API being called.
            },
        });

        return new Promise<Map<string,number>>((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/admin/dashboard/counts`, {
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
            }).then((response) => {
                if (response.status === 200) {
                    response.json().then((data: Map<string,number>) => {
                        resolve(data);
                    });
                } else {
                    reject(new Error("User is not authenticated"));
                }
            });
        });
    }
    return {
        getUserSignups,
        getProjects,
        getLicensableProperties,
        getCounts
    };
}