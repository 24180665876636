import React, {useEffect} from 'react';
import logo from './logo.svg';
import './App.css';
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
// import { Provider } from "react-redux";
import Router from "./router";
import "./index.css";

function App() {


  return (
      <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: process.env.REACT_APP_AUTH0_AUD || "",
            scope: "read:current_user update:current_user_metadata",
          }}
      >
          <Router />
      </Auth0Provider>
  );
}

export default App;
