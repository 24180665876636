import React, {useEffect} from "react";
import AppContainer from "./common/app_container";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import BlogIndex from "./pages/blog/index";
import Dashboard from "./pages/dashboard";
import {useAuth0} from "@auth0/auth0-react";
import NotAuthorized from "@src/pages/not_authorized";
import UserIndex from "@src/pages/users";
import UserEdit from "@src/pages/users/edit";
import ProjectIndex from "@src/pages/projects";
import ProjectEdit from "@src/pages/projects/edit";
import BlogEdit from "@src/pages/blog/edit";
function Router() {
  const { loginWithRedirect, isAuthenticated, isLoading, user } = useAuth0();
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading,isAuthenticated, loginWithRedirect]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
          <Dashboard />
      ),
    },
    {
      path: "/not-authorized",
      element: ( <NotAuthorized/>)
    },
    {
      path: "/blog",
      element: (
          <BlogIndex/>
      ),
    },
    {
      path: "/blog/edit/:id",
      element: (
          <BlogEdit/>
      ),
    },
    {
      path: "/blog/add",
      element: (
          <BlogEdit/>
      ),
    },
    {
      path: "/users",
      element: <UserIndex/>
    },
    {
      path: "/users/edit/:id",
      element: <UserEdit/>
    },
    {
      path: "/users/add",
      element: <UserEdit/>
    },
    {
      path: "/projects",
      element: <ProjectIndex/>
    },
    {
      path: "/projects/edit/:id",
      element: <ProjectEdit/>
    },
    {
      path: "/projects/add",
      element: <ProjectEdit/>
    }
    ]);
  if(isAuthenticated) {
    return (
        <RouterProvider router={router}/>
    );
  } else {
    return <div>Logging in...</div>;

  }
}

export default Router;