import React from "react";
import AppContainer from "../../common/app_container";
import {useUserAPI} from "@src/api/user.api";
import {User} from "@src/model/user";
import {useNavigate, useParams} from "react-router-dom";
import {useForm, SubmitHandler} from "react-hook-form";
import Alert, {AlertProps} from "@src/common/alert";

interface ProjectEditProps {

}
const ProjectEdit: React.FC<ProjectEditProps> = (props) => {

    return (
        <AppContainer>
            <h1 className="text-3xl">Edit Project</h1>
        </AppContainer>
    )
}
export default ProjectEdit;