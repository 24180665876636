import React, {useEffect} from "react";
import AppContainer from "../../common/app_container";
import {useBlogAPI, BlogPost} from "@src/api/blog_api";
import {useNavigate, useParams} from "react-router-dom";
import {useForm, SubmitHandler, Controller} from "react-hook-form";
import Alert, {AlertProps} from "@src/common/alert";
import {format} from "date-fns";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor,
    Autoformat,
    Bold,
    Italic,
    Underline,
    BlockQuote,
    Base64UploadAdapter,
    CKFinder,
    CKFinderUploadAdapter,
    CloudServices,
    CKBox,
    Essentials,
    Heading,
    Image,
    ImageCaption,
    ImageResize,
    ImageStyle,
    ImageToolbar,
    ImageUpload,
    PictureEditing,
    Indent,
    IndentBlock,
    Link,
    List,
    MediaEmbed,
    Mention,
    Paragraph,
    PasteFromOffice,
    Table,
    TableColumnResize,
    TableToolbar,
    TextTransformation,
    Undo
} from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

interface UserEditProps {

}
const BlogEdit: React.FC<UserEditProps> = (props) => {
    const {id} = useParams();
    const blogAPI = useBlogAPI();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);
    const [status, setStatus] = React.useState<AlertProps|null>(null);
    const [isSaving, setIsSaving] = React.useState(false);
    const {
        register,
        handleSubmit,
        watch,
        reset,
        control,
        formState: { errors },
    } = useForm<BlogPost>()
    const onSubmit: SubmitHandler<BlogPost> = (data) => {
        setIsSaving(true);
        if(data.published_at) {
            data.published_at = new Date(data.published_at);
        }
        blogAPI.saveBlogPost(data).then(() => {
            setIsSaving(false);
            setStatus({type: "success", message:"Post saved successfully"});
            setTimeout(() => {
                navigate("/blog");

            }, 3000)
        })
            .catch((err) => {
                setIsSaving(false);
                setStatus({type: "error", message:"Error saving post: " + err.message});
            });
    }

    useEffect(() => {
        if(id) {
            blogAPI.getBlogPost(id).then((response) => {
                reset(response);
            });
        }
    }, [id]);

    return (
        <AppContainer>
            <div className="container">
                <div className="flex justify-between">
                    <h1 className="text-3xl">{id?"Edit Post":"Add Post"}</h1>
                    <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" type="submit" form="edit_form" disabled={isSaving}>
                        Save
                    </button>
                </div>
                {status && <Alert {...status}/>}
                <form onSubmit={handleSubmit(onSubmit)} id="edit_form">
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                            <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                                Title
                            </label>
                            <div className="mt-2">
                                <div
                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        {...register("title")}
                                        type="text"
                                        placeholder="Title"
                                        autoComplete="title"
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="slug" className="block text-sm font-medium leading-6 text-gray-900">
                                Permalink
                            </label>
                            <div className="mt-2">
                                <div
                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <input
                                        {...register("slug")}
                                        type="text"
                                        placeholder="Permalink"
                                        autoComplete="slug"
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    />


                                </div>
                                <p id="helper-text-explanation"
                                   className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                                    All lower case, words separated by dashes, no special characters.<br/>
                                    <a className="font-medium text-blue-600 hover:underline dark:text-blue-500"
                                       href={`https://www.theofficial.ai/blog/${watch("slug")}`}>
                                        https://www.theofficial.ai/blog/{watch("slug")}</a>
                                </p>
                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="status" className="block text-sm font-medium leading-6 text-gray-900">
                                Publish Status
                            </label>
                            <div className="mt-2">
                                <select
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"

                                    {...register("status")}>
                                    <option value="DRAFT">Draft</option>
                                    <option value="PUBLISHED">Published</option>
                                    <option value="ARCHIVED">Archived</option>
                                </select>


                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="published_at" className="block text-sm font-medium leading-6 text-gray-900">
                                Published At
                            </label>
                            <div className="mt-2">
                                <div
                                    className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                    <Controller
                                        name={"published_at"}
                                        control={control}
                                        render={({ field }) => (
                                    <input
                                        value={(field.value ? field.value.toISOString().substring(0,10):"")}
                                        defaultValue={(field.value ? field.value.toISOString().substring(0,10):"")}
                                        onChange={(e) => field.onChange(new Date(e.target.value))}
                                        type="date"
                                        className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                    />)}
                                       />
                                </div>


                            </div>
                        </div>
                        <div className="sm:col-span-4">
                            <label htmlFor="content" className="block text-sm font-medium leading-6 text-gray-900">
                                Content
                            </label>
                            <div className="mt-2">
                                <Controller name="content" control={control} render={({field}) => (
                                    <CKEditor
                                        editor={ClassicEditor}
                                        // data={field.value}
                                        onChange={(event, editor) => field.onChange(editor.getData())}
                                        onBlur={field.onBlur}
                                        data={field.value || ""}
                                        config={{
                                            toolbar: {
                                                items: [
                                                    'undo',
                                                    'redo',
                                                    '|',
                                                    'heading',
                                                    '|',
                                                    'bold',
                                                    'italic',
                                                    'underline',
                                                    '|',
                                                    'link',
                                                    'uploadImage',
                                                    'ckbox',
                                                    'insertTable',
                                                    'blockQuote',
                                                    'mediaEmbed',
                                                    '|',
                                                    'bulletedList',
                                                    'numberedList',
                                                    '|',
                                                    'outdent',
                                                    'indent',
                                                ],
                                            },
                                            plugins: [
                                                Autoformat,
                                                BlockQuote,
                                                Bold,
                                                CKFinder,
                                                CKFinderUploadAdapter,
                                                CloudServices,
                                                Essentials,
                                                Heading,
                                                Image,
                                                ImageCaption,
                                                ImageResize,
                                                ImageStyle,
                                                ImageToolbar,
                                                ImageUpload,
                                                Base64UploadAdapter,
                                                Indent,
                                                IndentBlock,
                                                Italic,
                                                Link,
                                                List,
                                                MediaEmbed,
                                                Mention,
                                                Paragraph,
                                                PasteFromOffice,
                                                PictureEditing,
                                                Table,
                                                TableColumnResize,
                                                TableToolbar,
                                                TextTransformation,
                                                Underline,
                                                Undo,
                                            ],
                                            initialData: field.value,
                                        }}
                                    />
                                )}/>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </AppContainer>
    )
};

export default BlogEdit;