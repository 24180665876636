import React from 'react';
import {
    CheckCircleIcon,
    ExclamationTriangleIcon,
    InformationCircleIcon,
    XCircleIcon,
    XMarkIcon
} from '@heroicons/react/20/solid'
export interface AlertProps {
    type: "info" | "success" | "warning" | "error";
    message: string | React.ReactNode
}
const Alert: React.FC<AlertProps> = (props) => {

    switch(props.type) {
        case "success":
            return (
                <div className="rounded-md bg-green-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <CheckCircleIcon aria-hidden="true" className="h-5 w-5 text-green-400"/>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm font-medium text-green-800">{props.message}</p>
                        </div>
                        <div className="ml-auto pl-3">
                            <div className="-mx-1.5 -my-1.5">
                                <button
                                    type="button"
                                    className="inline-flex rounded-md bg-green-50 p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
                                >
                                    <span className="sr-only">Dismiss</span>
                                    <XMarkIcon aria-hidden="true" className="h-5 w-5"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        case "warning":
            return (
                <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400"/>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-yellow-700">
                                {props.message}
                            </p>
                        </div>
                    </div>
                </div>
            )
        case "info":
            return (
                <div className="rounded-md bg-blue-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-blue-400"/>
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-blue-700">
                                {props.message}
                            </p>
                        </div>
                    </div>
                </div>
            )
        case "error":
            return (
                <div className="rounded-md bg-red-50 p-4">
                    <div className="flex">
                        <div className="flex-shrink-0">
                            <XCircleIcon aria-hidden="true" className="h-5 w-5 text-red-400" />
                        </div>
                        <div className="ml-3">
                            <p className="text-sm text-red-800">
                                {props.message}
                            </p>
                        </div>
                    </div>
                </div>
            )

    }
}
export default Alert;