import {EventCount, useAdminDashboardAPI} from "@src/api/admin_dashboard";
import React, {useEffect} from "react";
import AppContainer from "../../common/app_container";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
interface BarChartState{
    name: string;
    data: number[];
}
const Dashboard: React.FC = () => {
    const adminDashboardAPI = useAdminDashboardAPI();
    const [userSignups, setUserSignups] = React.useState<EventCount[]>([]);
    const [projects, setProjects] = React.useState<EventCount[]>([]);
    const [licensableProperties, setLicensableProperties] = React.useState<EventCount[]>([]);
    const [stats, setStats] = React.useState<any>(new Map<string, number>());
    useEffect(() => {
        adminDashboardAPI.getUserSignups().then((data:EventCount[]) => {
            setUserSignups(data);
        });
        adminDashboardAPI.getProjects().then((data:EventCount[]) => {
            setProjects(data);
        });
        adminDashboardAPI.getLicensableProperties().then((data:EventCount[]) => {
            setLicensableProperties(data);
        })
        adminDashboardAPI.getCounts().then((data) => {
            setStats(data);
        })
    }, []);
    const timeSeriesOptions: ApexOptions = {
        colors: ['#3C50E0', '#80CAEE'],
        chart: {
            fontFamily: 'Satoshi, sans-serif',
            type: 'bar',
            height: 335,
            stacked: true,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 0,
                columnWidth: '25%',
                borderRadiusApplication: 'end',
                borderRadiusWhenStacked: 'last',
            },
        },
        dataLabels: {
            enabled: false,
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            fontFamily: 'Satoshi',
            fontWeight: 500,
            fontSize: '14px',
        },
        fill: {
            opacity: 1,
        },
    }
    return (
        <AppContainer>
            <div>
                <h1 className="text-3xl">Dashboard</h1>
                <div className="flex flex-row flex-wrap">

                    <ReactApexChart
                        options={{
                            ...timeSeriesOptions,
                            title: {text: 'User Signups'},
                            xaxis: {
                                categories: userSignups.map((x) => x.date),
                            },
                        }}
                        series={[
                            {
                                name: "User Signups",
                                data: userSignups.map((x) => x.count),
                            }]
                        }
                        type="bar"
                        height={350}
                        width={350}
                    />
                    <ReactApexChart
                        options={{
                            ...timeSeriesOptions,
                            title: {text: 'Projects'},
                            xaxis: {
                                categories: projects.map((x) => x.date),
                            },
                        }}
                        series={[
                            {
                                name: "Projects",
                                data: projects.map((x) => x.count),
                            }]
                        }
                        type="bar"
                        height={350}
                        width={350}
                    />
                    <ReactApexChart
                        options={{
                            ...timeSeriesOptions,
                            title: {text: 'Talent'},
                            xaxis: {
                                categories: licensableProperties.map((x) => x.date),
                            },
                        }}
                        series={[
                            {
                                name: "Talent",
                                data: licensableProperties.map((x) => x.count),
                            }]
                        }
                        type="bar"
                        height={350}
                        width={350}
                    />
                </div>
                <div>
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                        {Object.keys(stats).map((key) => {
                            let item = {name: key, stat: stats[key]}
                            return <div key={item.name}
                                        className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                                <dt className="truncate text-sm font-medium text-gray-500">{item.name}</dt>
                                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
                            </div>
                        })}
                    </dl>
                </div>
            </div>
        </AppContainer>
    );
}
export default Dashboard;